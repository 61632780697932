<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Area"
          label-for="area"
        >
          <custom-v-select
            id="area"
            v-model="selectedAreas"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="areaOptions"
            label="text"
            placeholder="Select Area"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Salesman"
          label-for="salesman"
        >
          <custom-v-select
            id="salesman"
            v-model="selectedSalesmans"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="salesmanOptions"
            label="text"
            placeholder="Select Salesman"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Statement Template"
          label-for="statement-template"
        >
          <custom-v-select
            id="statement-template"
            v-model="selectedStatementTemplates"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statementTemplateOptions"
            label="text"
            placeholder="Select Statement Template"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import axios from 'axios'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    CustomVSelect,
  },
  data() {
    return {
      areaOptions: [],
      selectedAreas: [],
      salesmanOptions: [],
      selectedSalesmans: [],
      statementTemplateOptions: [],
      selectedStatementTemplates: [],
    }
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/accounts/areas_options')
        .then(res => {
          this.areaOptions = res.data.data
        })

      axios.get('/accounts/salesmans_options')
        .then(res => {
          this.salesmanOptions = res.data.data
        })

      axios.get('/accounts/statement_template_options')
        .then(res => {
          this.statementTemplateOptions = res.data.data
        })
    },
    filtersChangeHandler() {
      const filtersdata = { page: this.page, per_page: this.perPage }
      if (this.selectedAreas.length > 0) {
        filtersdata.areas = this.selectedAreas.map(area => area.id)
      }
      if (this.selectedSalesmans.length > 0) {
        filtersdata.salesmans = this.selectedSalesmans.map(salesman => salesman.id)
      }
      if (this.selectedStatementTemplates.length > 0) {
        filtersdata.statement_templates = this.selectedStatementTemplates.map(statementTemplate => statementTemplate.id)
      }
      this.$emit('filtersUpdated', filtersdata)
    },
  },
}
</script>
