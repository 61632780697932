<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <statement-details
      v-if="openStatementId"
      :statement-id="openStatementId"
      @modal-closed="openStatementId = null"
    />

    <div v-if="!loading && !loadingError">
      <div class="text-right">
        <b-button
          variant="primary"
          :disabled="downloading || items.length === 0"
          class="mb-1 mr-1"
          @click="downloadStatements"
        >
          Download All
          <b-spinner
            v-if="downloading"
            small
            label="Small Spinner"
          />
        </b-button>
        <b-button
          variant="primary"
          :disabled="sendingAllStatementEmail || items.length === 0"
          class="mb-1"
          @click="sendAllStatementsEmail()"
        >
          Send All
          <b-spinner
            v-if="sendingAllStatementEmail"
            small
            label="Small Spinner"
          />
        </b-button>
      </div>

      <b-table
        :items="items"
        :fields="tableFields"
        responsive="sm"
        striped
        hover
        show-empty
        class="compact-table"
        no-local-sorting
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="sortingChanged"
      >
        <template #cell(start_date)="data">
          {{ formatedDate(data.item.start_date) }}
        </template>
        <template #cell(end_date)="data">
          {{ formatedDate(data.item.end_date) }}
        </template>
        <template #cell(due_date)="data">
          {{ formatedDate(data.item.due_date) }}
        </template>
        <template #cell(pay_status)="data">
          <status-icon :value="data.item.pay_status" />
        </template>
        <template #cell(paid_amount)="data">
          {{ formateNumber(data.item.paid_amount) }}
        </template>
        <template #cell(total_amount)="data">
          {{ formateNumber(data.item.total_amount) }}
        </template>
        <template #cell(actions)="data">
          <feather-icon
            v-b-tooltip.hover="{boundary:'window'}"
            title="View Details"
            icon="EyeIcon"
            size="20"
            class="cursor-pointer"
            @click="displayDetails(data.item.id)"
          />

          <span class="mx-1">
            <b-spinner
              v-if="downloadingStatement && downloadingStatementId === data.item.id"
              label="Small Spinner"
              small
            />
            <feather-icon
              v-else
              v-b-tooltip.hover="{boundary:'window'}"
              title="Download Statement"
              icon="DownloadIcon"
              size="20"
              class="cursor-pointer"
              @click="downloadStatement(data.item.id, data.item.start_date, data.item.end_date)"
            />
          </span>
          <span>
            <b-spinner
              v-if="sendingStatementEmail && sendingStatementId === data.item.id"
              label="Small Spinner"
              small
            />
            <feather-icon
              v-else
              v-b-tooltip.hover="{boundary:'window'}"
              title="Send Statement"
              icon="SendIcon"
              size="20"
              class="cursor-pointer"
              @click="sendStatementsEmail([data.item.id])"
            />
          </span>
        </template>
      </b-table>
      <detailed-pagination
        v-if="!loading && !loadingError"
        :total-items="totalItems"
        :per-page="perPage"
        :current-page="currentPage"
        @page-changed="(page) => {currentPage = page}"
      />
    </div>
  </div>
</template>

<script>
import {
  BSpinner, BTable, BAlert, VBTooltip, BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import download from 'downloadjs'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dateMixin from '@/mixins/dateMixin'
import statementMixin from '@/mixins/statementMixin'
import formatMixin from '@/mixins/formatMixin'
import StatementDetails from '../Statement/StatementDetails.vue'
import StatusIcon from '../UI/StatusIcon.vue'
import DetailedPagination from '../UI/DetailedPagination.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner, BTable, BAlert, BButton, StatementDetails, StatusIcon, DetailedPagination,
  },
  mixins: [dateMixin, statementMixin, formatMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      items: [],
      tableFields: [
        { key: 'id', label: 'Statement ID', sortable: true },
        { key: 'party', label: 'Party', sortable: true },
        { key: 'start_date', label: 'Start Date', sortable: true },
        { key: 'end_date', label: 'End Date', sortable: true },
        { key: 'due_date', label: 'Due Date', sortable: true },
        {
          key: 'total_amount', label: 'Total Amount', sortable: true, class: 'text-right',
        },
        {
          key: 'paid_amount', label: 'Paid Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'pay_status', label: 'Pay Status', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
      ],
      openStatementId: null,
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'party',
      sortDesc: false,
      downloading: false,
    }
  },
  watch: {
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
    currentPage() {
      this.fetchItems()
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    fetchItems() {
      this.loading = true
      axios.post('/accounts/print_statements_list', {
        page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        filters: {
          ...this.filters,
        },
      })
        .then(res => {
          this.items = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    displayDetails(statementId) {
      this.openStatementId = statementId
    },
    downloadStatements() {
      this.downloading = true
      const statementIds = this.items.map(item => item.id)
      axios.get('/accounts/download_statements', {
        responseType: 'blob',
        params: { statement_ids: JSON.stringify(statementIds) },
      })
        .then(res => {
          const contentType = res.headers['content-type']
          download(res.data, 'Statements', contentType)
          this.downloading = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.error || 'Something went wrong!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.downloading = false
        })
    },
    sendAllStatementsEmail() {
      const statementIds = this.items.map(item => item.id)
      this.sendStatementsEmail(statementIds, { isSendAll: true })
    },
  },
}
</script>
